import React, {useEffect, useState, useRef} from "react";
import {useTranslation} from "react-i18next";
import InputSpec from "../../components/computation-input-specific/InputSpecific";
import {mergeDoc} from "../../services/firebaseFunctions";
import {styles} from "../../components/utils/Styles";
import SimpleMaterialUITable from "../../components/simple-table";
import ImageUpload from "../../components/image-upload/ImageUpload";
import {
	fetchQuickrJson,
	fetchProductionJson,
} from "../../services/apiFunctions";
import {options} from "../../config/options";

interface SolarEnergyBuildingSpecsProps {
	updateId: string;
	selectedInteraction: string;
	interactionData: any[]; // Change any[] to the appropriate type
	pageReady: boolean;
	template: any; // Change any to the appropriate type
}

const SolarEnergyBuildingSpecs: React.FC<SolarEnergyBuildingSpecsProps> = ({
	updateId,
	selectedInteraction,
	interactionData,
	pageReady,
	template,
}) => {

	const {t} = useTranslation();
	const [productionY1, setProductionY1] = useState(
		interactionData.find(
			(interaction) => interaction.id === selectedInteraction
		)?.fields?.["5m50idGbxXVinxyCqxQx"] || ""
	);
	const [calculationId, setCalculationId] = useState(
		interactionData.find(
			(interaction) => interaction.id === selectedInteraction
		)?.fields?.fbzu2wmWI3MdG2BcWnIs || ""
	);
	const [error, setError] = useState<boolean>(false);
	const [existingImage, setExistingImage] = useState<any>(null); // Change any to the appropriate type
	const [loading, setLoading] = useState<boolean>(true);
	const prevCalculationId = useRef(calculationId);
	const prevProductionY1 = useRef(productionY1);
	const [productionInputType, setProductionInputType] = useState<string | null>(
		null
	); // Change any to the appropriate type
	const prevProductionInputType = useRef(productionInputType);
	const [production, setProduction] = useState<any[]>(
		interactionData.find(
			(interaction) => interaction.id === selectedInteraction
		)?.production || []
	);

	useEffect(() => {
		const status =
			production &&
			existingImage === true &&
			production &&
			production.length > 0;

		if (existingImage && !(status === pageReady && loading === false)) {
			mergeDoc("solar_energy_computations", updateId, {
				progress: {
					1: {
						done: status,
						name: "Quickr information",
					},
				},
			});
		}
	}, [production, pageReady, existingImage, updateId, loading]);

	useEffect(() => {
		if (interactionData.length > 0) {
			const selectedInteractionData = interactionData.find(
				(interaction) => interaction.id === selectedInteraction
			);

			if (selectedInteractionData) {
				setProductionInputType(
					selectedInteractionData.fields?.juVY4NRKJwVnhrwHuNLa || ""
				);
				setCalculationId(
					selectedInteractionData.fields?.fbzu2wmWI3MdG2BcWnIs || ""
				);
				setProductionY1(
					selectedInteractionData.fields?.["5m50idGbxXVinxyCqxQx"] || ""
				);
				setProduction(selectedInteractionData.production || []);
			}
			setLoading(false);
		}
	}, [interactionData, selectedInteraction]);

	useEffect(() => {
		const fetchProductionDataQuickr = async () => {
			if (calculationId !== "" && loading === false) {
				const response = await fetchQuickrJson(updateId, calculationId);
				console.log(response.data.length);
				if (response?.data && response?.data.length > 0) {
					await mergeDoc(
						`solar_energy_computations/${updateId}/computation_inputs`,
						`${selectedInteraction}`,
						{
							production: response.data || [],
							computation_present: false,
							// simultaneous: {}
						}
					);
					setLoading(false);
				} else {
					console.log(selectedInteraction, "wuth");
					await mergeDoc(
						`solar_energy_computations/${updateId}/computation_inputs`,
						`${selectedInteraction}`,
						{
							production: [],
							computation_present: false,
						}
					);
					await mergeDoc("solar_energy_computations", updateId, {
						progress: {
							1: {
								done: false,
							},
						},
					});
					setError(true);
					setLoading(false);
				}
			}
		};

		const fetchProductionDataY1 = async () => {
			if (productionY1 > 0 && loading === false) {
				const response = await fetchProductionJson(updateId, productionY1);
				if (response?.data && response?.data.length > 0) {
					mergeDoc(
						`solar_energy_computations/${updateId}/computation_inputs`,
						`${selectedInteraction}`,
						{
							production: response.data || [],
							computation_present: false,
						}
					);
					setLoading(false);
				} else {
					setError(true);
					mergeDoc(
						`solar_energy_computations/${updateId}/computation_inputs`,
						`${selectedInteraction}`,
						{
							production: [],
							computation_present: false,
						}
					);

					mergeDoc("solar_energy_computations", updateId, {
						progress: {
							1: {
								done: false,
							},
						},
					});
				}
			}
		};

		if (
			(prevProductionInputType.current !== null &&
				prevProductionInputType.current !== undefined && //condition for an existing computation)
				prevProductionInputType.current !== productionInputType) ||
			production === undefined ||
			(production && production.length === 0 && error === false) ||
			(productionInputType === options.production_type_year_1 &&
				prevProductionY1.current > 0 &&
				productionY1 > 0 &&
				prevProductionY1.current !== productionY1) ||
			(productionInputType === options.production_type_quickr &&
				prevCalculationId.current !== "" &&
				calculationId !== "" &&
				prevCalculationId.current !== calculationId)
		) {
			if (productionInputType === options.production_type_quickr) {
				fetchProductionDataQuickr();
				console.log("fetchProductionDataQuickr");
			} else if (productionInputType === options.production_type_year_1) {
				fetchProductionDataY1();
				console.log("fetchProductionDataY1");
			}
		}
		prevCalculationId.current = calculationId;
		prevProductionY1.current = productionY1;
		prevProductionInputType.current = productionInputType;
		// Update prevCalculationId ref with the current calculationId
	}, [
		loading,
		error,
		selectedInteraction,
		calculationId,
		productionY1,
		production,
		updateId,
		productionInputType,
	]);

	return (
		<div style={{...styles.container, marginLeft: "20px"}}>
			{selectedInteraction !== "" && (
				<div>
					<div
						style={{
							...styles.rightPanelStyle,
							marginLeft: "-10px",
							minWidth: "300px",
							maxHeight: "30vh",
							minHeight: "30vh",
						}}
					>
						<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
							{t("energyProduction")}
						</h3>
						<InputSpec
							updateId={updateId}
							interactionId={selectedInteraction}
							interactionData={interactionData}
							filter={"energy_production"}
							templateUnfiltered={template}
							computationType={"solar_energy_computations"}
						/>
					</div>
					<div
						style={{
							marginTop: "10px",
							marginLeft: "-10px",
							minWidth: "500px",
							maxWidth: "500px",
						}}
					>
						<ImageUpload
							path={`solar_energy_computations/${updateId}/${selectedInteraction}/images`}
							existingImage={existingImage}
							setExistingImage={setExistingImage}
						/>
					</div>
				</div>
			)}
			<div
				style={{
					...styles.rightPanelStyle,
					maxHeight: "90vh",
					minWidth: "300px",
					maxWidth: "300px",
					marginLeft: "10px",
				}}
			>
				<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
					{t("predictedGeneration")}{" "}
					{/* Translation key for "Voorspelde productie" */}
				</h3>
				{production && Object.keys(production).length > 0 && (
					<SimpleMaterialUITable data={production} />
				)}
			</div>
		</div>
	);
};

export default SolarEnergyBuildingSpecs;
