import React, {useEffect, useState} from "react";
import {mergeDoc, fetchDocsData} from "../../services/firebaseFunctions";
import {styles} from "../../components/utils/Styles";
import EditableTable from "../../components/simultaneous-edit-table";
import StackedBarChartWithLine from "../../components/plots/StackedBarLine";

import {fetchPVSimultaneous} from "../../services/apiFunctions";
import InputSpec from "../../components/computation-input-specific/InputSpecific";
import {clientInputsColumns} from "../../components/utils/clientInputsColumns";
import { useTranslation } from 'react-i18next'; // Import for translations
import {options} from "../../config/options";

interface Option {
	id: string;
	data_type: string;
	name: string;
	name_en: string;
	text: string;
	text_en: string;
	value: number | string;
}

const coefs: Record<string, {peak: number; non_peak: number; total: number}> = {
	"0": {
		peak: 0.0666590043972105,
		non_peak: 0.0299725667426191,
		total: 0.0966315711398296,
	},
	"1": {
		peak: 0.0637656457803341,
		non_peak: 0.0275039349761289,
		total: 0.0912695807564629,
	},
	"2": {
		peak: 0.0617560516139169,
		non_peak: 0.0230432087242086,
		total: 0.0847992603381255,
	},
	"3": {
		peak: 0.0535901797664119,
		non_peak: 0.0238287799896277,
		total: 0.0774189597560396,
	},
	"4": {
		peak: 0.0452297926297922,
		non_peak: 0.0174537508429408,
		total: 0.062683543472733,
	},
	"5": {
		peak: 0.054346460764553,
		non_peak: 0.0266937358912519,
		total: 0.0810401966558049,
	},
	"6": {
		peak: 0.0614106984876482,
		non_peak: 0.0261033971391943,
		total: 0.0875140956268424,
	},
	"7": {
		peak: 0.0708414560073909,
		non_peak: 0.0240056763104663,
		total: 0.0948471323178572,
	},
	"8": {
		peak: 0.0708414560073909,
		non_peak: 0.0240056763104663,
		total: 0.0948471323178572,
	},
	"9": {
		peak: 0.0471574804661581,
		non_peak: 0.016223408403515,
		total: 0.0633808888696731,
	},
	"10": {
		peak: 0.0595598225920807,
		non_peak: 0.0238834135330419,
		total: 0.0834432361251226,
	},
	"11": {
		peak: 0.0588499044115677,
		non_peak: 0.0232744982120842,
		total: 0.0821244026236519,
	},
};

interface UsageRecord {
	month: string;
	peak: number | null;
	non_peak: number | null;
	total: number;
}

interface InteractionData {
	id: string;
	simultaneous?: {
		usage_data: UsageRecord[];
		generation_perc: number;
	};
	fields?: {
		[key: string]: any;
	};
	production?: any[];
}

interface Props {
	updateId: string;
	selectedInteraction: string;
	interactionData: InteractionData[];
	generalData: any; // Replace 'any' with the correct type
	pageReady: boolean;
	template: any; // Replace 'any' with the correct type
}

const months: string[] = [
	"jan",
	"feb",
	"mrt",
	"apr",
	"mei",
	"jun",
	"jul",
	"aug",
	"sep",
	"okt",
	"nov",
	"dec",
];

const getAllOptions = async () => {
	const options = await fetchDocsData("options");

	return options.map((option: any) => {
		return option; // Return each individual option object
	});
}

const SolarEnergySimultaneous: React.FC<Props> = ({
	updateId,
	selectedInteraction,
	interactionData,
	generalData,
	pageReady,
	template,
}) => {
	const { t } = useTranslation(); // Translation hook
	const [simultaneousData, setSimultaneousData] = useState(() => {
		const selectedInteractionData = interactionData.find(
			(interaction) => interaction.id === selectedInteraction
		);

		if (
			selectedInteractionData &&
			selectedInteractionData?.simultaneous &&
			selectedInteractionData.simultaneous?.usage_data
		) {
			return selectedInteractionData.simultaneous.usage_data;
		} else {
			return months.map((month) => ({
				month,
				peak: null,
				non_peak: null,
				total: 0,
			}));
		}
	});
	const usage_input_column: string = clientInputsColumns.usage_input_type;
	const [inputType, setInputType] = useState<string>("");
	const [totalUsage, setTotalUsage] = useState<number | null>(null);
	const [simultaneousDays, setSimultaneousDays] = useState(0);
	const [production, setProduction] = useState<number>(0);
	const [dataChanged, setDataChanged] = useState(false);
	const [interaction, setInteraction] = useState(selectedInteraction);
	const [allOptions, setAllOptions] = useState<Option[]>([]);
	const [weekendDays, setWeekendDays] = useState<string>();
	const [weekendPerc, setWeekendPerc] = useState<number>();
	const [maxPerc, setMaxPerc] = useState<number>();

	const getOptionValue = (id: string, allOptions: Option[]) => {
		const option = allOptions.find((option) => option.id === id);
		if (option) {
			if (option.data_type === "INTEGER") {
				return Number(option.value);
			}
			else if (option.data_type === "STRING") {
				return option.name;
			}
		}
		else {
			return id;
		}
	}

	useEffect(() => {
		const currentInteraction = interactionData.find(
			(interaction) => interaction.id === selectedInteraction
		);

		if (!currentInteraction) {
			return; // Return early if current interaction is not found
		}
		
		const status =
			currentInteraction.simultaneous &&
			currentInteraction.simultaneous.usage_data &&
			currentInteraction.simultaneous.usage_data.length > 0;

		if (status !== undefined && status !== pageReady) {
			mergeDoc("solar_energy_computations", updateId, {
				progress: {
					3: {
						done: status,
						name: "Simultaneous",
					},
				},
			});
		}
	}, [interactionData, selectedInteraction, pageReady, updateId]);

	useEffect(() => {
		if (dataChanged === true) {
			mergeDoc(
				`solar_energy_computations/${updateId}/computation_inputs`,
				selectedInteraction,
				{
					computation_present: false,
					pdf_present: false,
				}
			);
		}
	}, [dataChanged, selectedInteraction, updateId]);


	function calculateMultipliedCoefs(
		simultaneousData: any,
		totalUsage: number
	): any[] {
		const multipliedCoefs: any[] = [];
	
		for (const key in simultaneousData) {
		if (coefs.hasOwnProperty(key)) {
			multipliedCoefs[key as any] = {};
			
			for (const subKey in coefs[key]) {
			if (coefs[key].hasOwnProperty(subKey)) {
				const previous: any = coefs[key];
				
				multipliedCoefs[key as any][subKey] = Math.round(
				previous[subKey] * Number(totalUsage)
				);
			}
			}
			
			multipliedCoefs[key as any]["month"] = simultaneousData[key]["month"];
		}
		}
	
		return multipliedCoefs;
	}
	useEffect(() => {
		
		if (interactionData) {
			const selectedInteractionData: InteractionData | undefined =
				interactionData.find(
					(interaction) => interaction.id === selectedInteraction
				);
			if (
				selectedInteractionData?.production &&
				selectedInteractionData?.production.length > 0
			) {
				setProduction(
					selectedInteractionData?.production[0]?.kwh_production ?? 0
				);
				setInputType(
					selectedInteractionData.fields?.[usage_input_column] || ""
				);
				setTotalUsage(
					selectedInteractionData.fields?.[
						clientInputsColumns.electricity_usage
					] || 0
				);
				setSimultaneousDays(
					Number(Object.keys(options).find(
						key => options[key] === selectedInteractionData.fields?.[clientInputsColumns.simultaneous_days]
					)?.replace(/\D/g, '')) || 0
				);
			}
			if (selectedInteractionData?.fields) {
				if (weekendDays !== selectedInteractionData.fields?.["7fkY4TgOVVHTJWYiTMA7"]) {
					setWeekendDays(selectedInteractionData.fields?.["7fkY4TgOVVHTJWYiTMA7"] as string);
					setDataChanged(true);
				}
				if (weekendPerc !== selectedInteractionData.fields?.["IlTtaeDe3lZjrdQAInYz"]) {
					setWeekendPerc(selectedInteractionData.fields?.["IlTtaeDe3lZjrdQAInYz"] as number);
					setDataChanged(true);
				}
				if (maxPerc !== selectedInteractionData.fields?.["i72cL5LK8x8qNxd5OHew"]) {
					setMaxPerc(selectedInteractionData.fields?.["i72cL5LK8x8qNxd5OHew"] as number);
					setDataChanged(true);
				}
				
				if (
					inputType === options.type_usage_total &&
					Number(totalUsage) > 0 && 
					!(simultaneousData.every(
						(item) => item["total"] > 0 && !isNaN(item["total"])
					))
				) {
					const multipliedCoefs = calculateMultipliedCoefs(simultaneousData, totalUsage as any);
					setSimultaneousData(multipliedCoefs);
					setDataChanged(true);
					setTimeout(() => {}, 0);
				}

				else if (
					(selectedInteractionData.fields?.[usage_input_column] !== inputType &&
						inputType !== "") ||
					(selectedInteractionData.fields?.[
						clientInputsColumns.electricity_usage
					] !== totalUsage &&
						totalUsage !== null) ||
					!!((Number(Object.keys(options).find(
						key => options[key] === selectedInteractionData.fields?.[clientInputsColumns.simultaneous_days]
					)?.replace(/\D/g, '')) || 0) !== simultaneousDays &&
						simultaneousDays !== 0 &&
						interaction === selectedInteraction)
				) {		
					// for anything but total usage
					if (
						selectedInteractionData.fields?.[
							clientInputsColumns.electricity_usage
						] !== totalUsage && !(inputType === options.type_usage_total &&
						Number(totalUsage) > 0) &&
						totalUsage !== null
					) {
						setSimultaneousData(
							months.map((month) => ({
								month,
								peak: null,
								non_peak: null,
								total: 0,
							}))
						);
					}
					else {
					// for total usage
					const multipliedCoefs = calculateMultipliedCoefs(simultaneousData, selectedInteractionData.fields?.[
						clientInputsColumns.electricity_usage
					] as any);
					setSimultaneousData(multipliedCoefs);
					setTimeout(() => {}, 0);
					}
					setDataChanged(true);

				}
				setInteraction(selectedInteraction);
			}
		}
	}, [
		interactionData,
		simultaneousData,
		usage_input_column,
		selectedInteraction,
		dataChanged,
		totalUsage,
		inputType,
		simultaneousDays,
		interaction
	]);

	useEffect(() => {
		const addSimultaneous = async () => {
			if (allOptions.length === 0) {
				const options = await getAllOptions();
				setAllOptions(options);
			}
			const weekend_days_value = await getOptionValue(weekendDays as string, allOptions);
			const allTotalsFilled = simultaneousData?.every(
				(item) => item["total"] > 0 && !isNaN(item["total"])
			);
			const sumOfValidTotals = simultaneousData.reduce((accumulator, item) => {
				if (item["total"] > 0 && !isNaN(item["total"])) {
					return accumulator + item["total"];
				} else {
					return accumulator;
				}
			}, 0);

			if (
				allTotalsFilled === true &&
				sumOfValidTotals > 0 &&
				simultaneousDays > 0
			) {
				try {
					const selectedInteractionData: InteractionData | undefined =
						interactionData.find(
							(interaction) => interaction.id === selectedInteraction
						);
					// Fetch PV Simultaneous data
					const summary_simultaneous = await fetchPVSimultaneous({
						client_id: updateId,
						days: simultaneousDays,
						usage: simultaneousData,
						production: production,
						weekend_days: weekend_days_value,
						weekend_perc: weekendPerc,
						max_perc: maxPerc,
						language_version: generalData[0].fields?.["QQCT34RNyYnuu1UdpV9j"],
					});
					// Merge computed data to Firestore
					if (selectedInteractionData?.fields) {
						if (summary_simultaneous?.data.generation_perc) {
							mergeDoc(
								`solar_energy_computations/${updateId}/computation_inputs`,
								`${selectedInteraction}`,
								{
									simultaneous: summary_simultaneous.data,
									fields: {
										"9UFkhbqlhCGpHNiteEeA":
											summary_simultaneous.data.generation_perc,
									},
								}
							);
						} else if (
							sumOfValidTotals !==
							selectedInteractionData.fields?.["9UFkhbqlhCGpHNiteEeA"]
						) {
							mergeDoc(
								`solar_energy_computations/${updateId}/computation_inputs`,
								`${selectedInteraction}`,
								{
									fields: {
										HCYFCWj7cfFJGmrV73Od: sumOfValidTotals,
									},
								}
							);
						}
					}
					setDataChanged(false);
				} catch (error) {
					console.error("Error fetching or merging data:", error);
				}
			} else {
				console.log("Not all totals are filled");
			}
		};
		if (dataChanged === true) {
			addSimultaneous();
		}
	}, [
		dataChanged,
		simultaneousData,
		simultaneousDays,
		updateId,
		selectedInteraction,
		interactionData,
		production,
		generalData,
	]);

	return (
		<div style={{...styles.container, marginLeft: "20px"}}>
			<div>
				{selectedInteraction !== "" && (
					<div
						style={{
							...styles.rightPanelStyle,
							minWidth: "400px",
							minHeight: "39vh",
							maxHeight: "39vh",
							marginLeft: "-10px",
						}}
					>
						<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
							{" "}
							{t('simultaneous')} 
						</h3>
						{
							<InputSpec
								updateId={updateId}
								interactionId={selectedInteraction}
								interactionData={interactionData}
								filter={"simultaneous"}
								templateUnfiltered={template}
								computationType="solar_energy_computations"
							/>
						}
						<p
							style={{
								fontSize: "0.8rem",
								fontFamily: "basic",
								fontWeight: "bold",
							}}
						>
							{/* Replace 'value' with the actual value you want to display */}
							{t('calculatedSimultaneousPerc')}:{" "}
							{Math.round(
								(interactionData.find(
									(interaction) => interaction.id === selectedInteraction
								)?.simultaneous?.generation_perc || 0) * 100
							) + "%"}
						</p>
					</div>
				)}

				{simultaneousData &&
					interactionData.find(
						(interaction) => interaction.id === selectedInteraction
					)?.fields?.[usage_input_column] &&
					simultaneousData?.every(
						(item) => item["total"] > 0 && !isNaN(item["total"])
					) && (
						<div
							style={{
								...styles.rightPanelStyle,
								maxWidth: "400px",
								marginTop: "10px",
								minHeight: "36vh",
								marginLeft: "-10px",
							}}
						>
							<StackedBarChartWithLine
								data={simultaneousData}
								production={production}
							/>
						</div>
					)}
			</div>
			<div style={{marginLeft: "10px", minWidth: "400px"}}>
				<div
					style={{
						...styles.rightPanelStyle,
						minHeight: "80vh",
						maxHeight: "90vh",
						position: "relative",
					}}
				>
					<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
					{t('usageInput')} {/* Translated "Simultaneous Input" */}
					</h3>

					{simultaneousData &&
						interactionData.find(
							(interaction) => interaction.id === selectedInteraction
						)?.fields?.[usage_input_column] &&
						interactionData.find(
							(interaction) => interaction.id === selectedInteraction
						)?.fields?.[clientInputsColumns.simultaneous_days] && (
							<>
								<EditableTable
									data={simultaneousData}
									setData={setSimultaneousData}
									setDataChanged={setDataChanged}
									editType={inputType}
									total={
										interactionData.find(
											(interaction) => interaction.id === selectedInteraction
										)?.fields?.[clientInputsColumns.electricity_usage] || 0
										}
								/>
							</>
						)}
				</div>
			</div>
		</div>
	);
};

export default SolarEnergySimultaneous;
