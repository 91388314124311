import React, {useState, useEffect, useRef} from "react";
import {useParams, useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DealSelector from "../../components/layout/deal-selector";
import Main from "../main/Main";
import {
	fetchDocData,
	mergeDoc,
	fetchDocDataStream,
	removeDoc,
	fetchDocsDataStream,
	fetchDocsData
} from "../../services/firebaseFunctions";
import StageStepper from "../../components/stage-stepper";
import SolarRentalResultSummary from "../../screens/energy-rental-result";
import ContentRental from "../../screens/energy-rental-report";
import SolarRentalComputationSpecs from "../../screens/energy-rental-computation-specs";
import SelectableProgressBar from "../../components/selectable-progressbar";
import {styles} from "../../components/utils/Styles";
import UnauthorizedPage from "../../screens/unauthorized";
import {useTranslation} from "react-i18next";

import {
	fetchComputation,
	updateFilteredSteps,
	fetchInputFieldsData
} from "../../components/utils/computationInitialization";
import {
	fetchInteractions,
	fetchAll,
	fetchRooms,
} from "../../services/streamingFunctions";
import RentalInitialize from "../../screens/solar-rental-initialize";
import useCustomClaims from "../../hooks/useCustomClaims";
import {getDifferentKeysWithValues} from "../utils";
import useFetchCRMData from "../../hooks/useFetchCRMData";
interface Template {
	[key: string]: any;
}

interface Option {
	id: string;
	data_type: string;
	name: string;
	name_en: string;
	text: string;
	text_en: string;
	value: number | string;
}

const getAllOptions = async () => {
	const options = await fetchDocsData("options");

	return options.map((option: any) => {
		return option; // Return each individual option object
	});
}

const allInteractionsPresent = (recordList: any): boolean => {
	// Check if recordList is empty
	if (!recordList || recordList.length === 0) {
		return false;
	}

	// Check if all records in the record list have a PDF
	return recordList.every(
		(record: any) =>
			record.computation_present === true && record.pdf_present === true
	);
};

const steps = [
	"Buca gegevens",
	"Aanpassingen",
	"Config resultaat",
	"Rapportage",
];

interface Pages {
	[key: string]: number;
}

const pages: Pages = {
	initialize: 0,
	details: 1,
	result: 2,
	report: 3,
};

const SolarEnergy: React.FC = () => {
	const {dealId, selectedPageName} = useParams();
	const navigate = useNavigate();
	const computationType = "solar_rental_computations";
	const [clientId, setClientId] = useState(dealId || "");
	const [filteredSteps, setFilteredSteps] = useState<string[]>([]);
	const [showScreens, setShowScreens] = useState(false);
	const [activeStep, setActiveStep] = useState<number>(
		selectedPageName ? pages[selectedPageName] : 0
	);
	const [generalData, setGeneralData] = useState<any[]>([]);
	const [updateId, setUpdateId] = useState("");
	const [roomId, setRoomId] = useState("");
	const [listRooms, setListRooms] = useState<any[]>([]);
	const [computationData, setComputationData] = useState<any>({});
	const [progress, setProgress] = useState<any>({});
	const [differences, setDifferences] = useState<any[]>([]);
	const [loadedClientInputs, setLoadedClientInputs] = useState<any[]>([]);
	const [template, setTemplate] = useState<Template>({});
	const [allOptions, setAllOptions] = useState<Option[]>([]);

	const [templateGeneral, setTemplateGeneral] = useState<Template>({});
	const [templateGeneralPV, setTemplateGeneralPV] = useState<Template>({});
	const [updatedInteractionData, setUpdatedInteractionData] = useState<any[]>([]);
	const [inputCategories, setInputCategories] = useState<any[]>([]);

	const [globalState, setGlobalState] = useState<{country: string}>({
		country: "NL",
	});
	const [pageReady0, setPageReady0] = useState(false);
	const [pageReady1, setPageReady1] = useState(false);
	const [pageReady2, setPageReady2] = useState(false);
	const [pageReady3, setPageReady3] = useState(false);
	const [pageReady4, setPageReady4] = useState(false);
	const [pageReady5, setPageReady5] = useState(false);
	const [selectedInteraction, setSelectedInteraction] = useState<string>("");
	const [interactionData, setInteractionData] = useState<any[]>([]);

	const customClaims = useCustomClaims();
	const [crmDetails, setCRMDetails] = useFetchCRMData(clientId);
	const {t} = useTranslation();

	useEffect(() => {
		if (generalData && generalData.length > 0 && generalData[0].fields) {
			setGlobalState((prevState) => ({
				...prevState,
				country: generalData[0].fields?.QQCT34RNyYnuu1UdpV9j,
			}));
		}
	}, [generalData]);

	useEffect(() => {
		fetchDocsDataStream("input_categories", setInputCategories as any);
	}, [computationType]);

	useEffect(() => {
		if (interactionData) {
			let status = false;
			if (interactionData.length > 0) {
				status = allInteractionsPresent(interactionData);
			}
			if (!(status === pageReady5)) {
				setPageReady5(status);
				mergeDoc("solar_rental_computations", updateId, {
					progress: {
						5: {
							done: status,
							name: "Computation results",
						},
					},
				});
			}
		}
	}, [interactionData, updateId]);

	const getOptionValue = (id: string, allOptions: Option[]) => {
		const option = allOptions.find((option) => option.id === id);
		if (option) {
			if (option.data_type === "INTEGER") {
				return Number(option.value);
			}
			else if (option.data_type === "STRING") {
				return option.name;
			}
		}
		else {
			return id;
		}
	}

	useEffect(() => {
		const fetchDataForDifferences = async () => {
			if (allOptions.length === 0) {
				const options = await getAllOptions();
				setAllOptions(options);
			}
			if (interactionData.length > 1) {
				const differencesMap = new Map<string, {values: any[]; name: string}>();

				for (let i = 0; i < interactionData.length - 1; i++) {
					const currentDifferences =
						getDifferentKeysWithValues(interactionData);
					for (const diff of currentDifferences) {
						if (!differencesMap.has(diff.key)) {
							let values: any[] = [];
							for (const interaction of interactionData) {
								const {name, fields} = interaction;
								const row = {name, value: getOptionValue(fields?.[diff.key], allOptions)};
								values.push(row);
							}
							if (template && !Object.keys(template).includes(diff.key)) {
								const name = await fetchDocData("client_inputs", diff.key);

								differencesMap.set(diff.key, {
									values: values,
									name: name?.name,
								});
							} else if (template) {
								const name = template[diff.key];

								differencesMap.set(diff.key, {
									values: values,
									name: name?.name,
								});
							}
						}
					}
				}
				setDifferences(Array.from(differencesMap.values()));
			} else {
				setDifferences([]);
			}
		};

		fetchDataForDifferences();
	}, [interactionData]);

	useEffect(() => {
		fetchInteractions(updateId, computationType, setInteractionData);
		fetchRooms(updateId, computationType, setListRooms);
		fetchAll(updateId, computationType, setGeneralData);
	}, [updateId]);

	useEffect(() => {
		if (listRooms.length > 0) {
			setRoomId(listRooms.find((item) => item.name_clean === "all"));
		} else {
			setRoomId("");
		}
	}, [listRooms]);

	// Define fetchClientInputsTemplate outside of the useEffect if it doesn't
	async function fetchClientInputsTemplate(
		templateList: any,
		loadedClientInputs: any,
		inputCategories: any[]
	): Promise<{templateData: any; updatedLoadedClientInputs: any}> {
		const inputIds: string[] = templateList?.fields || [];
		const templateData: any = {};
		const updatedLoadedClientInputs = {...loadedClientInputs};

		for (const inputId of inputIds) {
			if (!(inputId in loadedClientInputs)) {
				try {
					const data = await fetchDocData("client_inputs", inputId);
					if (data?.options?.length > 0) {
						const updatedOptions = await Promise.all(
							data.options.map(async (option: string) => {
								if (option) {
									const optionData = await fetchDocData("options", option);
									return {...optionData, id: option};
								}
								return option;
							})
						);

						data.options = updatedOptions;
					}
					if (data?.input_categories?.length > 0) {
						const updatedOptions = await Promise.all(
							data.input_categories.map(async (input_category: string) => {
								if (input_category) {
									// Fetch option data and append its id to the result
									const optionData = inputCategories.find((item: any) => item.id === input_category);
									return {...optionData, id: input_category};
								}
								return input_category;
							})
						);
						data.input_categories = updatedOptions;
					}
					if (Object.keys(data).length > 0) {
						templateData[inputId] = data;
						updatedLoadedClientInputs[inputId] = data;
					}
				} catch (error) {
					console.error(`Failed to fetch data for inputId ${inputId}:`, error);
					// Handle or propagate error as appropriate
				}
			} else {
				templateData[inputId] = loadedClientInputs[inputId];
			}
		}

		return {templateData, updatedLoadedClientInputs};
	}

	useEffect(() => {
		const fetchTemplates = async () => {
			try {
				if (globalState.country) {
					const measureData = await fetchDocData(
						"templates",
						`pv_per_room_${globalState.country.toLowerCase()}`
					);

					const {templateData, updatedLoadedClientInputs} = await fetchClientInputsTemplate(measureData, loadedClientInputs, inputCategories);
					setLoadedClientInputs(updatedLoadedClientInputs);
					setTemplate(templateData);
				}
			} catch (error) {
				console.error("Error fetching template data: ", error);
				// Optionally, handle the error by updating the component state
			}
		};
		if (inputCategories.length > 0) {
			fetchTemplates();
		}
		// Assuming globalState.country and loadedClientInputs are stable references,
		// otherwise, consider their inclusion in the dependency array and handling potential re-fetches.
	}, [globalState.country, inputCategories]);

	// useEffect(() => {
	// 	const fetchTemplates = async () => {
	// 		try {
	// 			if (globalState.country) {
	// 				const measureData = await fetchDocData(
	// 					"templates",
	// 					`pv_per_room_${globalState.country.toLowerCase()}`
	// 				);
	// 				console.log("measureData", measureData);
	// 				console.log("inputCategories", inputCategories);
	// 				console.log("template", template);

	// 				// Fetch the input fields data and update loadedClientInputs
	// 				const updatedLoadedClientInputs = await fetchInputFieldsData(
	// 					templateRef.current, // Use ref's current value
	// 					measureData.fields,
	// 					inputCategories
	// 				);
	// 				// Update the ref but not trigger a re-render
	// 				templateRef.current = updatedLoadedClientInputs;
	// 				//loadedClientInputs = updatedLoadedClientInputs;
	// 				setTemplate(updatedLoadedClientInputs);
	// 			}
	// 		} catch (error) {
	// 			console.error("Error fetching template data: ", error);
	// 		}
	// 	};
	// 	if (inputCategories.length > 0) {
	// 		fetchTemplates();
	// 	}

	// }, [globalState.country, inputCategories]);

	useEffect(() => {
		if (selectedInteraction === "" || selectedInteraction === undefined) {
			setSelectedInteraction(
				interactionData.find((item) => item.name === "Algemene berekening")?.id
			);
		}
	}, [interactionData, selectedInteraction]);

	useEffect(() => {
		const fetchComputationData = async () => {
			try {
				if (clientId.trim() !== "") {
					setUpdateId("");
					setComputationData({});
					setShowScreens(false);
					setProgress({});
					setGeneralData([]);
					await fetchComputation(
						clientId,
						computationType,
						setUpdateId,
						setComputationData,
						setShowScreens
					);
					navigate(`/solar-rental/${clientId}/initialize`);
					setActiveStep(0);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		fetchComputationData();
	}, [clientId]);

	useEffect(() => {
		if (showScreens === true) {
			setActiveStep(0);
			setUpdateId(clientId);
		}
	}, [showScreens, clientId]);

	useEffect(() => {
		updateFilteredSteps(
			progress?.progress,
			steps,
			showScreens,
			updateId,
			setFilteredSteps
		);
		const pageReadyList = [
			setPageReady0,
			setPageReady1,
			setPageReady2,
			setPageReady3,
			setPageReady4,
			setPageReady5,
		];
		if (progress?.progress) {
			for (const key in progress.progress) {
				if (progress.progress[key].done) {
					pageReadyList[parseInt(key)](true);
				} else {
					pageReadyList[parseInt(key)](false);
				}
			}
		}
	}, [progress, showScreens, updateId]);

	useEffect(() => {
		const fetchClientData = async () => {
			try {
				if (updateId) {
					const unsubscribe = fetchDocDataStream(
						"solar_rental_computations",
						updateId,
						setProgress
					);
					return () => {
						unsubscribe();
					};
				}
			} catch (error) {
				console.error("Error fetching progress data:", error);
			}
		};
		fetchClientData();
	}, [updateId]);

	const findKeyByValue = (object: any, value: any) => {
		return Object.keys(object).find((key) => object[key] === value);
	};

	useEffect(() => {
		if (updateId !== "") {
			const nextPageName = findKeyByValue(pages, activeStep);
			navigate(`/solar-rental/${updateId}/${nextPageName}`);
		}
	}, [activeStep, updateId]);

	useEffect(() => {
		const fetchTemplate = async () => {
			try {
				let mdvGeneralData: string[] = [];
				let pvGeneralData: string[] = [];
				if (computationType === "solar_rental_computations") {
					const result = await fetchDocData("templates", "va_general");
					mdvGeneralData = result?.fields;
					const result2 = await fetchDocData("templates", "pv_general");
					pvGeneralData = result2?.fields;
				}

				const templateData: Record<string, any> = {};
				templateData["fields"] = {};
				for (const inputId of mdvGeneralData) {
					const data = await fetchDocData(`client_inputs`, `${inputId}`);
					if (data?.options?.length > 0) {
						const updatedOptions = await Promise.all(
							data.options.map(async (option: string) => {
								if (option) {
									const optionData = await fetchDocData("options", option);
									return {...optionData, id: option};
								}
								return option;
							})
						);

						data.options = updatedOptions;
					}
					if (data?.input_categories?.length > 0) {
						const updatedOptions = await Promise.all(
							data.input_categories.map(async (input_category: string) => {
								if (input_category) {
									// Fetch option data and append its id to the result
									const optionData = inputCategories.find((item: any) => item.id === input_category);
									return {...optionData, id: input_category};
								}
								return input_category;
							})
						);
						data.input_categories = updatedOptions;
					}
					templateData["fields"][inputId] = data;
				}

				const templateData2: Record<string, any> = {};
				templateData2["fields"] = {};
				for (const inputId of pvGeneralData) {
					const data = await fetchDocData(`client_inputs`, `${inputId}`);
					if (data?.options?.length > 0) {
						const updatedOptions = await Promise.all(
							data.options.map(async (option: string) => {
								if (option) {
									const optionData = await fetchDocData("options", option);
									return {...optionData, id: option};
								}
								return option;
							})
						);

						data.options = updatedOptions;
					}
					if (data?.input_categories?.length > 0) {
						const updatedOptions = await Promise.all(
							data.input_categories.map(async (input_category: string) => {
								if (input_category) {
									// Fetch option data and append its id to the result
									const optionData = inputCategories.find((item: any) => item.id === input_category);
									return {...optionData, id: input_category};
								}
								return input_category;
							})
						);
						data.input_categories = updatedOptions;
					}
					templateData2["fields"][inputId] = data;
				}
				if (templateData) {
					setTemplateGeneral({
						fields: {...templateData.fields, ...templateData2.fields},
					} as any);
				}
				if (templateData2) {
					setTemplateGeneralPV(templateData2 as any);
				}
			} catch (error) {
				console.error("Error fetching template: ", error);
			}
		};
		if (inputCategories.length > 0) {
			fetchTemplate();
		}
	}, [computationType, inputCategories]);

	useEffect(() => {
		if (interactionData.length > 0) {
			interactionData
				.filter((item) => item?.name === undefined)
				.forEach((item) => {
					if (item.id) {
						removeDoc(
							`${computationType}/${updateId}/computation_inputs`,
							item.id
						);
					}
				});
		}
		const updatedInteractionDataTemp =
			interactionData.length > 0
				? interactionData.filter((item) => item.name.includes("MWA"))
				: [];
		updatedInteractionDataTemp.push({
			name: "Verreken advies",
			name_en: "CRE Report",
			id: "report",
			rank: -1,
		});
		setUpdatedInteractionData(updatedInteractionData);
	}, [interactionData.length]);

	return customClaims &&
		(customClaims?.["praeter-advisor"] ||
			customClaims?.["admin"] ||
			customClaims?.["solar-energy-advisor"]) ? (
		<Main>
			<div
				style={{
					backgroundColor: "#267FB8",
					width: "100vw",
					padding: "10px",
					height: "10px",
				}}
			>
				<h2
					style={{
						textAlign: "center",
						marginTop: -9,
						color: "white",
						fontSize: "18px",
						fontFamily: "basic",
						fontWeight: "bold",
					}}
				>
					{t("solarRentalTitle")}
				</h2>
			</div>
			<div
				style={{
					display: "flex",
					backgroundColor: "#f0f0f0",
					minHeight: "100vh",
				}}
			>
				<div style={{display: "flex"}}>
					<div style={{flex: 1, maxWidth: "250px"}}>
						<DealSelector
							clientId={clientId}
							setClientId={setClientId}
							setShowScreens={setShowScreens}
							crmDetails={crmDetails}
							computationType={computationType}
						/>
						<StageStepper
							steps={steps}
							activeStep={activeStep}
							handleStepChange={setActiveStep}
							filteredSteps={filteredSteps}
						/>
					</div>
					<div style={{flex: 2}}>
						{activeStep === 0 &&
							Object.keys(templateGeneral).length > 0 &&
							Object.keys(templateGeneralPV).length > 0 && (
								<RentalInitialize
									updateId={updateId}
									computationData={computationData}
									generalData={generalData}
									setComputationData={setComputationData}
									roomId={roomId}
									setRoomId={setRoomId}
									pageReady={pageReady0}
									computationType={computationType}
									interactionData={interactionData}
									template={templateGeneral as any}
									templatePV={templateGeneralPV as any}
								/>
							)}
						{activeStep === 1 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={interactionData}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_rental_computations"
								/>
								<SolarRentalComputationSpecs
									updateId={updateId}
									selectedInteraction={selectedInteraction || roomId}
									interactionData={interactionData}
									pageReady={pageReady1}
									template={template}
								/>
							</div>
						)}
						{activeStep === 2 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={interactionData}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_rental_computations"
								/>
								<SolarRentalResultSummary
									updateId={updateId}
									selectedInteraction={selectedInteraction}
									interactionData={interactionData}
									template={template}
									generalData={generalData}
									pageReady={pageReady2}
								/>
							</div>
						)}

						{activeStep === 3 && interactionData.length > 0 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={
										interactionData.filter(
											(item) => item.name !== "Algemene berekening"
										).length > 0
											? updatedInteractionData
											: interactionData
									}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_rental_computations"
								/>
								<ContentRental
									updateId={updateId}
									selectedInteraction={selectedInteraction}
									interactionData={interactionData}
									generalData={generalData}
								/>
							</div>
						)}
					</div>
					<div
						style={{
							position: "absolute",
							bottom: 50,
							right: 80,
						}}
					>
						<IconButton
							onClick={() => setActiveStep((prevStep) => prevStep + 1)}
							disabled={
								activeStep === steps.length - 1 ||
								showScreens === false ||
								filteredSteps.slice(activeStep + 1).length === 0
							}
							color="primary"
						>
							<ArrowForwardIcon style={{fontSize: "40px"}} />
						</IconButton>
					</div>
				</div>
			</div>
		</Main>
	) : (
		<UnauthorizedPage />
	);
};

export default SolarEnergy;
