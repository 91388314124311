import React, {useEffect, useState, useCallback} from "react";
import {useTranslation} from "react-i18next";
import "./ImageUpload.css"; // Import your CSS file for styling
import {styles} from "../utils/Styles";
import "firebase/storage";
import {
	getDownloadURL,
	ref,
	listAll,
	uploadBytes,
	deleteObject,
	StorageReference,
	UploadTaskSnapshot,
} from "firebase/storage";
import {storage} from "../../config/firebase";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

const ImageUpload: React.FC<{
	path: string;
	existingImage: boolean;
	setExistingImage: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({path, existingImage, setExistingImage}) => {
	const {t} = useTranslation();
	const [selectedImage, setSelectedImage] = useState<{
		[key: string]: any;
	} | null>(null);
	const [filePath, setFilePath] = useState<string | null>(null);

	const uploadImage = async (
		storageRefImage: StorageReference,
		selectedImage: File,
		blob: Blob
	) => {
		if (!selectedImage) {
			console.log("No image selected");
			return;
		}
		console.log("Uploading image...");

		// Create a reference to the file
		const imageRef = ref(storageRefImage, selectedImage.name);
		// Upload the file
		try {
			console.log("Uploading file...");
			const snapshot: UploadTaskSnapshot | any = await uploadBytes(
				imageRef,
				blob
			);
			console.log("Uploaded a blob or file!", snapshot);
			setExistingImage(true);
		} catch (error) {
			console.error("Error uploading file", error);
		}
	};

	const listFiles = useCallback(
		async (storageRefImage: StorageReference) => {
			try {
				const res: any = await listAll(storageRefImage);
				if (res.items.length > 0 && res.items[0]?._location.path_) {
					const url = await getDownloadURL(res.items[0]);
					setFilePath(url);
					setSelectedImage({
						name: res.items[0]._location.path_.split("/").slice(-1)[0],
						// Add other properties as needed
					});
					setExistingImage(true);
				}
			} catch (error) {
				console.error("Error listing files", error);
			}
		},
		[setFilePath, setSelectedImage, setExistingImage] // Dependencies
	);

	useEffect(() => {
		// Call the function with the path to your folder
		try {
			listFiles(ref(storage, path)).catch((error) => {
				console.log(error);
			});
		} catch (error) {
			console.log(error);
		}
	}, [path, listFiles]);

	const dataURLtoBlob = (dataURL: string) => {
		const arr = dataURL.split(",");
		if (arr && arr.length > 1) {
			// Check if arr is not null and has at least two parts
			const mime: string = arr[0].match(/:(.*?);/)?.[1] || ""; // Use optional chaining and nullish coalescing
			const bstr = atob(arr[1]);
			let n = bstr.length;
			const u8arr = new Uint8Array(n);

			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}

			return new Blob([u8arr], {type: mime});
		}
		return null; // Return null if dataURL is invalid
	};

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const reader = new FileReader();

			reader.onloadend = () => {
				// Set the selected image and update the state
				setSelectedImage(file);
				setFilePath(reader.result as string);
				const blob: any = dataURLtoBlob(reader.result as string);
				uploadImage(ref(storage, path), file, blob);
			};

			// Read the image as a data URL
			reader.readAsDataURL(file);
		}
	};

	const handleRemoveImage = () => {
		// Remove the selected image
		const desertRef = ref(ref(storage, path), selectedImage?.name || "");
		// Delete the file
		deleteObject(desertRef)
			.then(() => {
				// File deleted successfully
			})
			.catch((error) => {
				// Uh-oh, an error occurred!
				console.error("Error deleting file", error);
			});

		setSelectedImage(null);
		setFilePath(null);
		setExistingImage(false);
	};

	return (
		<div
			className="image-upload-container"
			style={{
				...styles.rightPanelStyle,
				padding: "20px",
				height: "350px",
				position: "relative",
			}}
		>
			<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
				{t("intekening")} {/* Translation key for "Intekening" */}
			</h3>

			{!existingImage && (
				<input type="file" accept="image/*" onChange={handleImageChange} />
			)}
			<div className="button-container"></div>

			{filePath && (
				<div>
					<div className="image-preview">
						<img src={filePath} alt="Preview" />
					</div>
					{existingImage && (
						<IconButton
							onClick={handleRemoveImage}
							style={{position: "absolute", top: 30, right: 20}}
							color="primary"
							aria-label="delete"
						>
							<DeleteIcon />
						</IconButton>
					)}
				</div>
			)}
		</div>
	);
};

export default ImageUpload;
