import React, {useState, useEffect, useRef} from "react";
import {useParams, useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DealSelector from "../../components/layout/deal-selector";
import Main from "../main/Main";
import {
	fetchDocData,
	fetchDocDataStream,
	mergeDoc,
	fetchDocsDataStream,
	removeDoc,
	fetchDocsData
} from "../../services/firebaseFunctions";
import StageStepper from "../../components/stage-stepper";
import SolarEnergyBuildingSpecs from "../../screens/energy-solar-building-specs";
import SolarEnergySimultaneous from "../../screens/energy-solar-simultaneous";
import SolarEnergyCosts from "../../screens/energy-solar-costs";
import SolarEnergyResultSummary from "../../screens/energy-solar-result";
import Content from "../../screens/energy-solar-report";
import SolarEnergyComputationSpecs from "../../screens/energy-solar-computation-specs";
import SelectableProgressBar from "../../components/selectable-progressbar";
import {styles} from "../../components/utils/Styles";
import ComputationInitialize from "../../screens/computation-initialize";
import UnauthorizedPage from "../../screens/unauthorized";
import {useTranslation} from "react-i18next";
import {options} from "../../components/utils/collections/options";

import {
	fetchComputation,
	updateFilteredSteps,
	fetchInputFieldsData,
} from "../../components/utils/computationInitialization";
import {
	fetchInteractions,
	fetchAll,
	fetchRooms,
} from "../../services/streamingFunctions";
import useCustomClaims from "../../hooks/useCustomClaims";
import {getDifferentKeysWithValues} from "../utils";
import useFetchCRMData from "../../hooks/useFetchCRMData";

interface Template {
	[key: string]: any;
}

interface Option {
	id: string;
	data_type: string;
	name: string;
	name_en: string;
	text: string;
	text_en: string;
	value: number | string;
}

const getAllOptions = async () => {
	const options = await fetchDocsData("options");

	return options.map((option: any) => {
		return option; // Return each individual option object
	});
}

const allInteractionsPresent = (recordList: any): boolean => {
	if (!recordList || recordList.length === 0) {
		return false;
	}

	return recordList.every(
		(record: any) =>
			record.computation_present === true && record.pdf_present === true
	);
};

const SolarEnergy: React.FC = () => {
	const {t} = useTranslation();
	const {dealId, selectedPageName} = useParams();

	const navigate = useNavigate();
	const computationType = "solar_energy_computations";
	const [clientId, setClientId] = useState(dealId || "");
	const [filteredSteps, setFilteredSteps] = useState<string[]>([]);
	const [showScreens, setShowScreens] = useState(false);
	const [generalData, setGeneralData] = useState<any[]>([]);
	const [updateId, setUpdateId] = useState("");
	const [roomId, setRoomId] = useState("");
	const [listRooms, setListRooms] = useState<any[]>([]);
	const [computationData, setComputationData] = useState<any>({});
	const [progress, setProgress] = useState<any>({});
	const [differences, setDifferences] = useState<any[]>([]);
	const [allOptions, setAllOptions] = useState<Option[]>([]);

	const [crmDetails, setCRMDetails] = useFetchCRMData(clientId);
	const [inputCategories, setInputCategories] = useState<any[]>([]);
	const [template, setTemplate] = useState<Template>({});
	const templateRef = useRef(template);
	const [templateGeneral, setTemplateGeneral] = useState<Template>({});
	const templateGeneralRef = useRef(templateGeneral);
	const [globalState, setGlobalState] = useState<{country: string}>({
		country: "NL",
	});
	const [pageReady0, setPageReady0] = useState(false);
	const [pageReady1, setPageReady1] = useState(false);
	const [pageReady2, setPageReady2] = useState(false);
	const [pageReady3, setPageReady3] = useState(false);
	const [pageReady4, setPageReady4] = useState(false);
	const [pageReady5, setPageReady5] = useState(false);
	const [selectedInteraction, setSelectedInteraction] = useState<string>("");
	const [interactionData, setInteractionData] = useState<any[]>([]);
	const customClaims = useCustomClaims();

	const steps = [
		t("steps.generalInfo"),
		t("steps.buildingSpecs"),
		t("steps.calculationDetails"),
		t("steps.simultaneous"),
		t("steps.costs"),
		t("steps.resultSummary"),
		t("steps.reporting"),
	];

	const pages: any = {
		initialize: 0,
		building: 1,
		"computation details": 2,
		simultaneous: 3,
		costs: 4,
		result: 5,
		report: 6,
	};
	const [activeStep, setActiveStep] = useState<number>(
		selectedPageName ? pages[selectedPageName] : 0
	);

	// Use the user input field to define the country of the advice
	useEffect(() => {
		if (generalData && generalData.length > 0 && generalData[0].fields) {
			const country = (options['uk'] === generalData[0].fields?.QQCT34RNyYnuu1UdpV9j)? "UK": "NL";  // TODO: Hardcoded QQCT34RNyYnuu1UdpV9j blijft dit altijd hetzelfde?
			setGlobalState((prevState) => ({
				...prevState,
				country: country,
			}));
		}
	}, [generalData]);

	// Use the user input field to define the country of the advice
	useEffect(() => {
		fetchDocsDataStream("input_categories", setInputCategories as any);
	}, [computationType]);

	useEffect(() => {
		if (interactionData) {
			let status = false;
			if (interactionData.length > 0) {
				status = allInteractionsPresent(interactionData);
			}
			if (!(status === pageReady5)) {
				mergeDoc("solar_energy_computations", updateId, {
					progress: {
						5: {
							done: status,
							name: "Computation results",
						},
					},
				});
			}
		}
	}, [interactionData, updateId, pageReady5]);

	const getOptionValue = (id: string, allOptions: Option[]) => {
		const option = allOptions.find((option) => option.id === id);
		if (option) {
			if (option.data_type === "INTEGER") {
				return Number(option.value);
			}
			else if (option.data_type === "STRING") {
				return option.name;
			}
		}
		else {
			return id;
		}
	}

	useEffect(() => {
		const fetchDataForDifferences = async () => {
			if (allOptions.length === 0) {
				const options = await getAllOptions();
				setAllOptions(options);
			}
			if (interactionData.length > 1) {
				const differencesMap = new Map<string, {values: any[]; name: string}>();

				for (let i = 0; i < interactionData.length - 1; i++) {
					const currentDifferences =
						getDifferentKeysWithValues(interactionData);
					for (const diff of currentDifferences) {
						if (!differencesMap.has(diff.key)) {
							let values: any[] = [];
							for (const interaction of interactionData) {
								const {name, fields} = interaction;
								const row = {name, value: getOptionValue(fields?.[diff.key], allOptions)};
								values.push(row);
							}
							if (template && !Object.keys(template).includes(diff.key)) {
								const name = await fetchDocData("client_inputs", diff.key);

								differencesMap.set(diff.key, {
									values: values,
									name: name?.name,
								});
							} else if (template) {
								const name = template[diff.key];

								differencesMap.set(diff.key, {
									values: values,
									name: name?.name,
								});
							}
						}
					}
				}
				setDifferences(Array.from(differencesMap.values()));
			} else {
				setDifferences([]);
			}
		};

		fetchDataForDifferences();
	}, [interactionData]);

	useEffect(() => {
		fetchInteractions(updateId, computationType, setInteractionData);
		fetchRooms(updateId, computationType, setListRooms);
		fetchAll(updateId, computationType, setGeneralData);
	}, [updateId]);

	useEffect(() => {
		if (interactionData.length > 0) {
			interactionData
				.filter((item) => item?.name === undefined)
				.forEach((item) => {
					if (item.id) {
						removeDoc(
							`${computationType}/${updateId}/computation_inputs`,
							item.id
						);
					}
				});
		}
	}, [interactionData.length]);

	useEffect(() => {
		const fetchTemplates = async () => {
			try {
				console.log(`pv_per_room_${globalState.country.toLowerCase()}`);
				if (globalState.country) {
					const templateFields = await fetchDocData(
						"templates",
						`pv_per_room_${globalState.country.toLowerCase()}`
					);

					// Fetch the input fields data and update loadedClientInputs
					const updatedLoadedClientInputs = await fetchInputFieldsData(
						templateRef.current, // Use ref's current value
						templateFields.fields,
						inputCategories
					);
					// Update the ref but not trigger a re-render
					templateRef.current = updatedLoadedClientInputs;
					setTemplate(updatedLoadedClientInputs);
				}
			} catch (error) {
				console.error("Error fetching template data: ", error);
			}
		};
		if (inputCategories.length > 0) {
		fetchTemplates();
		}
	}, [globalState.country, inputCategories]);

	useEffect(() => {
		const fetchTemplateGeneral = async () => {
			try {
				let GeneralData: any;
				const result = await fetchDocData("templates", "pv_general");
				const generalFieldList: string[] = result?.fields;
				// Fetch the input fields data and update loadedClientInputs
				GeneralData = await fetchInputFieldsData(
					templateGeneralRef.current, // Use ref's current value
					generalFieldList,
					inputCategories
				);
				setTemplateGeneral(GeneralData as any);
			} catch (error) {
				console.error("Error fetching template: ", error);
			}
		};
		if (inputCategories.length > 0) {
		fetchTemplateGeneral();
		}
	}, [computationType, inputCategories]);

	useEffect(() => {
		const fetchComputationData = async () => {
			try {
				if (clientId.trim() !== "") {
					setUpdateId("");
					setComputationData({});
					setShowScreens(false);
					setProgress({});
					setUpdateId("");
					setComputationData({});
					setShowScreens(false);
					setProgress({});
					await fetchComputation(
						clientId,
						computationType,
						setUpdateId,
						setComputationData,
						setShowScreens
					);
					navigate(`/solar-energy/${clientId}/initialize`);
					setActiveStep(0);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		fetchComputationData();
	}, [clientId, computationType]);

	useEffect(() => {
		if (showScreens === true) {
			setActiveStep(0);
			setUpdateId(clientId);
		}
	}, [showScreens, clientId]);

	useEffect(() => {
		updateFilteredSteps(
			progress?.progress,
			steps,
			showScreens,
			updateId,
			setFilteredSteps
		);
		const pageReadyList = [
			setPageReady0,
			setPageReady1,
			setPageReady2,
			setPageReady3,
			setPageReady4,
			setPageReady5,
		];
		if (progress?.progress) {
			for (const key in progress.progress) {
				if (progress.progress[key].done) {
					pageReadyList[parseInt(key)](true);
				} else {
					pageReadyList[parseInt(key)](false);
				}
			}
		}
	}, [progress, showScreens, updateId, t]);

	useEffect(() => {
		const fetchClientData = async () => {
			try {
				if (updateId) {
					const unsubscribe = fetchDocDataStream(
						"solar_energy_computations",
						updateId,
						setProgress
					);
					return () => {
						unsubscribe();
					};
				}
			} catch (error) {
				console.error("Error fetching progress data:", error);
			}
		};
		fetchClientData();
	}, [updateId]);

	const findKeyByValue = (object: any, value: any) => {
		return Object.keys(object).find((key) => object[key] === value);
	};

	useEffect(() => {
		if (updateId !== "") {
			const nextPageName = findKeyByValue(pages, activeStep);
			navigate(`/solar-energy/${updateId}/${nextPageName}`);
		}
	}, [activeStep, updateId]);

	const updatedInteractionData = [
		...interactionData,
		{name: t("mwa"), id: "mwa", rank: -1},
		{name: t("comparisonMWA"), id: "comparison", rank: -2},
	];

	return customClaims &&
		(customClaims?.["praeter-advisor"] ||
			customClaims?.["admin"] ||
			customClaims?.["solar-energy-advisor"]) ? (
		<Main>
			<div
				style={{
					backgroundColor: "#a8629e",
					width: "100vw",
					padding: "10px",
					height: "10px",
				}}
			>
				<h2
					style={{
						textAlign: "center",
						marginTop: -9,
						color: "white",
						fontSize: "18px",
						fontFamily: "basic",
						fontWeight: "bold",
					}}
				>
					{t("solarEnergy")}
				</h2>
			</div>
			<div
				style={{
					display: "flex",
					backgroundColor: "#f0f0f0",
					minHeight: "100vh",
				}}
			>
				<div style={{display: "flex"}}>
					<div style={{flex: 1, maxWidth: "250px"}}>
						<DealSelector
							clientId={clientId}
							setClientId={setClientId}
							setShowScreens={setShowScreens}
							crmDetails={crmDetails}
							computationType={computationType}
						/>
						<StageStepper
							steps={steps}
							activeStep={activeStep}
							handleStepChange={setActiveStep}
							filteredSteps={filteredSteps}
						/>
					</div>
					<div style={{flex: 2}}>
						{activeStep === 0 && Object.keys(templateGeneral).length > 0 && (
							<ComputationInitialize
								updateId={updateId}
								crmDetails={crmDetails}
								computationData={computationData}
								setComputationData={setComputationData}
								generalData={generalData}
								templateGeneral={templateGeneral}
								roomId={roomId}
								setRoomId={setRoomId}
								listRooms={listRooms}
								setListRooms={setListRooms}
								pageReady={pageReady0}
								computationType={"solar_energy_computations"}
							/>
						)}

						{activeStep === 1 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={interactionData}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_energy_computations"
								/>
								<SolarEnergyBuildingSpecs
									updateId={updateId}
									selectedInteraction={selectedInteraction || roomId}
									interactionData={interactionData}
									pageReady={pageReady1}
									template={template}
								/>
							</div>
						)}
						{activeStep === 2 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={interactionData}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_energy_computations"
								/>
								<SolarEnergyComputationSpecs
									updateId={updateId}
									selectedInteraction={selectedInteraction || roomId}
									interactionData={interactionData}
									pageReady={pageReady2}
									template={template}
								/>
							</div>
						)}
						{activeStep === 3 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={interactionData}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_energy_computations"
								/>
								<SolarEnergySimultaneous
									updateId={updateId}
									selectedInteraction={selectedInteraction || roomId}
									interactionData={interactionData}
									generalData={generalData}
									pageReady={pageReady3}
									template={template}
								/>
							</div>
						)}
						{activeStep === 4 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={interactionData}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_energy_computations"
								/>
								<SolarEnergyCosts
									updateId={updateId}
									selectedInteraction={selectedInteraction}
									interactionData={interactionData}
									pageReady={pageReady4}
								/>
							</div>
						)}
						{activeStep === 5 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={interactionData}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_energy_computations"
								/>
								<SolarEnergyResultSummary
									updateId={updateId}
									selectedInteraction={selectedInteraction}
									interactionData={interactionData}
									template={template}
									generalData={generalData}
									pageReady={pageReady5}
								/>
							</div>
						)}

						{activeStep === 6 && interactionData.length > 0 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={
										interactionData.filter(
											(item) => item.name !== "Algemene berekening"
										).length > 0
											? updatedInteractionData
											: interactionData
									}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_energy_computations"
								/>

								<Content
									updateId={updateId}
									selectedInteraction={selectedInteraction}
									interactionData={interactionData}
									generalData={generalData}
								/>
							</div>
						)}
					</div>
					<div
						style={{
							position: "absolute",
							bottom: 50,
							right: 80,
						}}
					>
						<IconButton
							onClick={() => setActiveStep((prevStep) => prevStep + 1)}
							disabled={
								activeStep === steps.length - 1 ||
								showScreens === false ||
								filteredSteps.slice(activeStep + 1).length === 0
							}
							color="primary"
						>
							<ArrowForwardIcon style={{fontSize: "40px"}} />
						</IconButton>
					</div>
				</div>
			</div>
		</Main>
	) : (
		<UnauthorizedPage />
	);
};

export default SolarEnergy;
